<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col">
     <ProfileEmergency :userProfile="userProfile" :showtitle="false" class="mb-5" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileEmergency from '@/components/Profile/ProfileEmergency.vue'

export default {
  name: 'accountEmergency',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  components: {
    Loader,
    ProfileEmergency
  },
}
</script>